import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tickets--replacement-form"
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:frame-missing", (event) => {
      const { detail: { response, visit } } = event;
      event.preventDefault();

      visit(response.url);
    });
  }
}
